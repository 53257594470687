import apiClient from '@/services/axios'

const END_POINT = '/ortb/rtbzone'

export async function getRTBZoneById(id) {
  return apiClient.get(END_POINT + '/get?Id=' + id).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Get RTB zone data
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */
export async function getRTBZones(id = null) {
  const payload = id != null ? { Id: id } : null

  return apiClient.get(END_POINT + '/get', payload).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Get user feed data
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */

export async function getRTBZonesForUserAndPublisher(accManagerId, publisherId) {
  let paramAccManagerId = accManagerId
  let paramPublisherId = publisherId
  if (!paramAccManagerId) {
    paramAccManagerId = '0'
  } else if (!paramPublisherId) {
    paramPublisherId = '0'
  }
  return apiClient.get(END_POINT + '/get', {
    params: { AccManagerId: accManagerId, PublisherId: publisherId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
export async function getRTBZonesForPublisher(publisherId) {
  return apiClient.get(END_POINT + '/get', {
    params: { PublisherId: publisherId },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
export async function getRTBZonesShort() {
  return apiClient.get(END_POINT + '/get/short', {
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
/**
 * Create new RTB zone
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createRTBZone(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: false,
      map: 'rtbzoneId',
    },
    TeamId: {
      type: 'int',
      required: false,
      map: 'teamId',
    },
    PublisherId: {
      type: 'int',
      required: true,
      map: 'publisher',
    },
    PublisherName: {
      type: 'string',
      required: false,
      map: 'publisherName',
    },
    Name: {
      type: 'string',
      required: true,
    },
    BidsDailyLimit: {
      type: 'int',
      required: false,
      map: 'bidsDailyLimit',
    },
    ImpressionsLimitPerIp: {
      type: 'int',
      required: false,
      map: 'impressionsLimitPerIp',
    },
    RequestsDailyLimit: {
      type: 'int',
      required: false,
      map: 'requestsDailyLimit',
    },
    ImpressionsDailyLimit: {
      type: 'int',
      required: false,
      map: 'impressionsDailyLimit',
    },
    SpentDailyLimit: {
      type: 'float',
      required: false,
      map: 'spentDailyLimit',
    },
    BannerEnabled: {
      type: 'int',
      required: false,
      map: 'bannerEnabled',
    },
    VideoEnabled: {
      type: 'int',
      required: false,
      map: 'videoEnabled',
    },
    AudioEnabled: {
      type: 'int',
      required: false,
      map: 'audioEnabled',
    },
    NativeEnabled: {
      type: 'int',
      required: false,
      map: 'nativeEnabled',
    },
    PopEnabled: {
      type: 'int',
      required: false,
      map: 'popEnabled',
    },
    WebEnabled: {
      type: 'int',
      required: false,
      map: 'webEnabled',
    },
    AppEnabled: {
      type: 'int',
      required: false,
      map: 'appEnabled',
    },
    MaxBidfloor: {
      map: 'maxBidfloor',
      type: 'float',
      required: false,
    },
    MinBidfloor: {
      map: 'minBidfloor',
      type: 'float',
      required: false,
    },
    DomainsBrandsListMode: {
      type: 'int',
      required: false,
      map: 'domainsBrandsListMode',
    },
    DomainsBrandsList: {
      type: 'intArray',
      required: false,
      map: 'domainsBrandsList',
    },
    AppsListMode: {
      type: 'int',
      required: false,
      map: 'appsListMode',
    },
    AppsList: {
      type: 'intArray',
      required: false,
      map: 'appsList',
    },
    SizesListMode: {
      type: 'int',
      required: false,
      map: 'sizesListMode',
    },
    SizesList: {
      type: 'arr',
      required: false,
      map: 'sizesList',
    },
    PubIdList: {
      type: 'string',
      required: false,
    },
    IpBlacklists: {
      map: 'ipBlacklists',
      type: 'intArray',
      required: false,
    },
    PubIdListMode: {
      type: 'int',
      required: false,
      map: 'pubIdListMode',
    },
    CridList: {
      type: 'string',
      required: false,
      map: 'cridList',
    },
    CridListMode: {
      type: 'int',
      required: false,
      map: 'cridListMode',
    },
    PlatformDomain: {
      type: 'string',
      required: false,
      map: 'platformDomain',
    },
    SellersJsonDomain: {
      type: 'string',
      required: false,
      map: 'sellersJsonDomain',
    },
    Margin: {
      map: 'margin',
      type: 'float',
      required: false,
    },
    PublisherRevShare: {
      map: 'publisherRevShare',
      type: 'float',
      required: false,
    },
    Status: {
      map: 'status',
      type: 'int',
      required: false,
    },
    StatusString: {
      map: 'statusString',
      type: 'string',
      required: false,
    },
    MaxQps: {
      map: 'maxQps',
      type: 'int',
      required: false,
    },
    ZoneUrl: {
      map: 'zoneUrl',
      type: 'string',
      required: false,
    },
    Remotertbzones: {
      map: 'remoteRTBZones',
      type: 'arr',
      required: false,
    },
    Publisher: {
      map: 'publisher',
      type: 'obj',
      required: false,
    },
    ImpressionTrackingMethod: {
      map: 'trackingMethod',
      type: 'int',
      required: false,
    },
    CheckAdsTxt: {
      map: 'checkAdsTxt',
      type: 'int',
      required: false,
    },
    CheckAppAdsTxt: {
      map: 'checkAppAdsTxt',
      type: 'int',
      required: false,
    },
    CheckSellersJson: {
      map: 'checkSellersJson',
      type: 'int',
      required: false,
    },
    AdDomainsBrandsBlacklist: {
      map: 'AdDomainsBrandsBlacklist',
      type: 'string',
      required: false,
    },
    AdAppsBlacklist: {
      map: 'AdAppsBlacklist',
      type: 'string',
      required: false,
    },
    Countries: {
      type: 'objectArray',
      required: false,
    },
    DeviceTypes: {
      type: 'intArray',
      required: false,
    },
    AutoOptimize: {
      type: 'int',
      required: false,
    },
    PopAdmXml: {
      type: 'int',
      required: false,
    },
    NonEmptyReferer: {
      type: 'int',
      required: false,
    },
    RefererMatch: {
      type: 'int',
      required: false,
    },
    IpMatch: {
      type: 'int',
      required: false,
    },
    UaMatch: {
      type: 'int',
      required: false,
    },
    IabCategories: {
      map: 'iabCategories',
      type: 'arr',
      required: false,
    },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Format Subid list
  if (typeof preparedData.SubidList !== 'undefined' && preparedData.SubidList.length > 0) {
    preparedData.SubidList = preparedData.SubidList.join('\n')
  }
  // Format AdDomains Brands  Blacklist
  // if (typeof preparedData.AdDomainsBrandsBlacklist !== 'undefined' && preparedData.AdDomainsBrandsBlacklist.length > 0) {
  //   preparedData.AdDomainsBrandsBlacklist = preparedData.AdDomainsBrandsBlacklist.join('\n')
  // }

  return apiClient.post(END_POINT + '/add', { Rtbzone: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update RTB zone data
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateRTBZone(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  const parameters = {
    Id: {
      type: 'int',
      required: false,
      map: 'rtbzoneId',
    },
    TeamId: {
      type: 'int',
      required: false,
      map: 'teamId',
    },
    PublisherId: {
      type: 'int',
      required: true,
      map: 'publisher',
    },
    PublisherName: {
      type: 'string',
      required: false,
      map: 'publisherName',
    },
    Name: {
      type: 'string',
      required: true,
    },
    BidsDailyLimit: {
      type: 'int',
      required: false,
      map: 'bidsDailyLimit',
    },
    ImpressionsLimitPerIp: {
      type: 'int',
      required: false,
      map: 'impressionsLimitPerIp',
    },
    RequestsDailyLimit: {
      type: 'int',
      required: false,
      map: 'requestsDailyLimit',
    },
    ImpressionsDailyLimit: {
      type: 'int',
      required: false,
      map: 'impressionsDailyLimit',
    },
    SpentDailyLimit: {
      type: 'float',
      required: false,
      map: 'spentDailyLimit',
    },
    BannerEnabled: {
      type: 'int',
      required: false,
      map: 'bannerEnabled',
    },
    VideoEnabled: {
      type: 'int',
      required: false,
      map: 'videoEnabled',
    },
    AudioEnabled: {
      type: 'int',
      required: false,
      map: 'audioEnabled',
    },
    NativeEnabled: {
      type: 'int',
      required: false,
      map: 'nativeEnabled',
    },
    PopEnabled: {
      type: 'int',
      required: false,
      map: 'popEnabled',
    },
    WebEnabled: {
      type: 'int',
      required: false,
      map: 'webEnabled',
    },
    AppEnabled: {
      type: 'int',
      required: false,
      map: 'appEnabled',
    },
    MaxBidfloor: {
      map: 'maxBidfloor',
      type: 'float',
      required: false,
    },
    MinBidfloor: {
      map: 'minBidfloor',
      type: 'float',
      required: false,
    },
    DomainsBrandsListMode: {
      type: 'int',
      required: false,
      map: 'domainsBrandsListMode',
    },
    DomainsBrandsList: {
      type: 'intArray',
      required: false,
      map: 'domainsBrandsList',
    },
    AppsListMode: {
      type: 'int',
      required: false,
      map: 'appsListMode',
    },
    AppsList: {
      type: 'intArray',
      required: false,
      map: 'appsList',
    },
    SizesListMode: {
      type: 'int',
      required: false,
      map: 'sizesListMode',
    },
    SizesList: {
      type: 'arr',
      required: false,
      map: 'sizesList',
    },
    PubIdList: {
      type: 'string',
      required: false,
    },
    IpBlacklists: {
      map: 'ipBlacklists',
      type: 'intArray',
      required: false,
    },
    PubIdListMode: {
      type: 'int',
      required: false,
      map: 'pubIdListMode',
    },
    CridList: {
      type: 'string',
      required: false,
      map: 'cridList',
    },
    CridListMode: {
      type: 'int',
      required: false,
      map: 'cridListMode',
    },
    PlatformDomain: {
      type: 'string',
      required: false,
      map: 'platformDomain',
    },
    SellersJsonDomain: {
      type: 'string',
      required: false,
      map: 'sellersJsonDomain',
    },
    Margin: {
      map: 'margin',
      type: 'float',
      required: false,
    },
    PublisherRevShare: {
      map: 'publisherRevShare',
      type: 'float',
      required: false,
    },
    Status: {
      map: 'status',
      type: 'int',
      required: false,
    },
    StatusString: {
      map: 'statusString',
      type: 'string',
      required: false,
    },
    MaxQps: {
      map: 'maxQps',
      type: 'int',
      required: false,
    },
    ZoneUrl: {
      map: 'zoneUrl',
      type: 'string',
      required: false,
    },
    Remotertbzones: {
      map: 'remoteRTBZones',
      type: 'intArray',
      required: false,
    },
    Dsppopfeeds: {
      map: 'dsppopfeeds',
      type: 'intArray',
      required: false,
    },
    Campaigns: {
      map: 'campaigns',
      type: 'arr',
      required: false,
    },
    Publisher: {
      map: 'publisher',
      type: 'obj',
      required: false,
    },
    ImpressionTrackingMethod: {
      map: 'trackingMethod',
      type: 'int',
      required: false,
    },
    CheckAdsTxt: {
      map: 'checkAdsTxt',
      type: 'int',
      required: false,
    },
    CheckAppAdsTxt: {
      map: 'checkAppAdsTxt',
      type: 'int',
      required: false,
    },
    CheckSellersJson: {
      map: 'checkSellersJson',
      type: 'int',
      required: false,
    },
    AdDomainsBrandsBlacklist: {
      map: 'AdDomainsBrandsBlacklist',
      type: 'string',
      required: false,
    },
    AdAppsBlacklist: {
      map: 'AdAppsBlacklist',
      type: 'string',
      required: false,
    },
    Countries: {
      type: 'objectArray',
      required: false,
    },
    DeviceTypes: {
      type: 'intArray',
      required: false,
    },
    AutoOptimize: {
      type: 'int',
      required: false,
    },
    PopAdmXml: {
      type: 'int',
      required: false,
    },
    NonEmptyReferer: {
      type: 'int',
      required: false,
    },
    RefererMatch: {
      type: 'int',
      required: false,
    },
    IpMatch: {
      type: 'int',
      required: false,
    },
    UaMatch: {
      type: 'int',
      required: false,
    },
    IabCategories: {
      map: 'iabCategories',
      type: 'arr',
      required: false,
    },
  }
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  return apiClient.post(END_POINT + '/update', { Rtbzone: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Change RTB zone status
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function changeRTBZoneStatus(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    Id: {
      type: 'int',
      required: true,
    },
    // Status: { type: 'int', required: true },
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload, parameters)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }

  // Get status endpoint
  let requestType = '/activate'
  if (payload.status === 1) {
    requestType = '/deactivate'
  } else if (payload.status === 2) {
    requestType = '/delete'
  }

  return apiClient.post(END_POINT + requestType, { Rtbzone: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Duplicate existing Feed
 *
 * @param {int} id - Feed id
 * @returns {Promise} - Request promise
 */
export async function cloneRTBZone(id) {
  // todo add type check
  return apiClient.post(END_POINT + '/duplicate', {
    Rtbzone: { Id: id },
  }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
