import Vue from 'vue'
import Vuex from 'vuex'
import { getAds, createAd, updateAd, changeAdStatus, getAdsForCampaign, getAdsForUserAndCampaign } from '@/api/display/ad'
import { getAdsShort } from '../../../api/display/ad'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_AD(state, payload) {
      const ad = state.list.find(item => item.Id === parseInt(payload.Id))
      if (ad !== null && ad !== undefined) {
        Object.assign(ad, {
          ...payload,
        })
      }
    },
  },
  actions: {
    LOAD_ADS_FOR_CAMPAIGN({ commit, dispatch, rootState }, payload) {
      getAdsForCampaign(payload.CampaignId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_ADS_FOR_USER_CAMP({ commit, dispatch, rootState }, payload) {
      getAdsForUserAndCampaign(payload.AccManagerId, payload.CampaignId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_ADS({ commit, dispatch, rootState }) {
      return getAds().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
        return true
      })
    },
    LOAD_ADS_SHORT({ commit, dispatch, rootState }) {
      return getAdsShort().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
        return true
      })
    },
    CREATE_AD({ commit, dispatch, rootState }, { payload, callback }) {
      createAd(payload).then(response => {
        if (response) {
          if (typeof response.type !== 'undefined' && response.type === 'error') {
            Vue.prototype.$notification.error({
              message: 'Invalid data',
              description: response.message,
            })
          } else if (response.Status === 'Ok') {
            Vue.prototype.$notification.success({
              message: 'Ad Created',
              description: 'Ad has been successfully created.',
            })
            callback()
          }
        }
        // TODO: add new ad to store object
        dispatch('LOAD_ADS')
      })
    },
    UPDATE_AD({ commit, dispatch, rootState }, { id, payload, callback }) {
      updateAd({ id, ...payload }).then(response => {
        if (response) {
          if (response.type === 'error') {
            Vue.prototype.$notification.error({
              message: 'Validation Error',
              description: response.message,
            })
          } else {
            commit('SET_AD', response.Ad)
            Vue.prototype.$notification.success({
              message: 'Ad Updated',
              description: 'Ad has been successfully updated.',
            })
            callback()
          }
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changeAdStatus(payload).then(response => {
        if (response) {
          commit('SET_AD', response.Ad)
          Vue.prototype.$notification.success({
            message: (response.Ad.Status === 2 ? 'Ad Deleted' : 'Status Changed'),
            description: (response.Ad.Status === 2 ? 'Ad successfully deleted.' : 'Ad status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getAdData: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getAds: (state) => {
      return state.list
    },
    getActiveAds: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
