import Vue from 'vue'
import Vuex from 'vuex'
import { getPopFeeds, createPopFeed, updatePopFeed, changePopFeedStatus, getPopFeedsForPublisher, getPopFeedsForUserAndPublisher } from '@/api/display/popfeed'
import { getPopFeedsShort } from '../../../api/display/popfeed'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_FEED(state, payload) {
      const popfeed = state.list.find(item => item.Id === parseInt(payload.Id))
      Object.assign(popfeed, {
        ...payload,
      })
    },
  },
  actions: {
    LOAD_FEEDS_FOR_PUBLISHER({ commit, dispatch, rootState }, payload) {
      getPopFeedsForPublisher(payload.PublisherId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_FEEDS_FOR_USER_PUB({ commit, dispatch, rootState }, payload) {
      getPopFeedsForUserAndPublisher(payload.AccManagerId, payload.PublisherId).then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_FEEDS({ commit, dispatch, rootState }) {
      getPopFeeds().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    LOAD_FEEDS_SHORT({ commit, dispatch, rootState }) {
      getPopFeedsShort().then(response => {
        if (response) {
          commit('SET_STATE', {
            list: response,
          })
        }
      })
    },
    CREATE_FEED({ commit, dispatch, rootState }, { payload }) {
      return new Promise((resolve, reject) => {
        createPopFeed(payload).then(response => {
          if (response) {
            if (typeof response.type !== 'undefined' && response.type === 'error') {
              Vue.prototype.$notification.error({
                message: 'Invalid data',
                description: response.message,
              })
              reject(response.message)
            } else if (response.Status === 'Ok') {
              Vue.prototype.$notification.success({
                message: 'Pop Feed Created',
                description: 'Pop Feed has been successfully created.',
              })
              resolve(response)
            }
          }
          dispatch('LOAD_FEEDS')
        }).catch(error => {
          reject(error)
        })
      })
    },
    UPDATE_FEED({ commit, dispatch, rootState }, { id, payload, callback }) {
      updatePopFeed({ id, ...payload }).then(response => {
        if (response) {
          // commit('SET_FEED', response.popfeed)
          Vue.prototype.$notification.success({
            message: 'Pop Feed Updated',
            description: 'Pop Feed has been successfully updated.',
          })
          callback()
        }
      })
    },
    CHANGE_STATUS({ commit, dispatch, rootState }, payload) {
      changePopFeedStatus(payload).then(response => {
        if (response) {
          commit('SET_FEED', response.Popfeed)
          Vue.prototype.$notification.success({
            message: (response.Popfeed.Status === 2 ? 'Pop Feed Deleted' : 'Status Changed'),
            description: (response.Popfeed.Status === 2 ? 'Pop Feed successfully deleted.' : 'Pop Feed status successfully changed.'),
          })
        }
      })
    },
  },
  getters: {
    getPopFeedData: (state) => (id) => {
      return state.list.find(item => item.Id === parseInt(id))
    },
    getPopFeeds: (state) => {
      return state.list
    },
    getActivePopFeeds: (state) => {
      return state.list.filter(ele => ele.Status === 0)
    },
  },
}
