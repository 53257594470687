import Vue from 'vue'
import apiClient from '@/services/axios'

const END_POINT = '/ortb/creative'

/**
 * Get Creatives
 *
 * @param {int} id - User ID
 * @returns {Promise} - Request promise
 */
export async function getCreatives(id = null) {
  const payload = id != null ? { Id: id } : null

  return apiClient.get(END_POINT + '/get', payload).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Get Creative upload path
 */
export function getActionURL() {
  return apiClient.defaults.baseURL + END_POINT + '/add'
}

/**
 * Create new Creative
 *
 * @param {Object} payload - Data
 * @returns {Promise} - Request promise
 */
export async function createCreative(payload, type) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Prepare and validate data
  // const preparedData = apiClient.prepare(payload)
  // if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
  //   return preparedData
  // }
  delete apiClient.defaults.headers.common.Accept
  apiClient.defaults.headers['Content-Type'] = type
  return apiClient.post(END_POINT + '/add/upload', payload).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update Creative
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateCreative(payload) {
  // if (typeof payload !== 'object' || payload == null) {
  //   return false
  // }
  // Prepare and validate data
  // const preparedData = apiClient.prepare(payload)
  // if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
  //   return preparedData
  // }

  return apiClient.post(END_POINT + '/update', { Creative: payload }).then(response => {
    // console.log(payload, 'payload')
    if (response) {
      // Vue.prototype.$notification.success({
      //   message: 'Creative Updated.',
      //   description: `Creative: ${payload.Name} Updated.`,
      // })
      return response.data
    }
    return false
  }).catch(err => {
    console.log(err)
    Vue.prototype.$notification.error({
      message: 'Invalid Data.',
    })
  })
}

/**
 * Change Creative
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function changeCreativeStatus(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Prepare and validate data
  const preparedData = apiClient.prepare(payload)
  if (typeof preparedData.type !== 'undefined' && preparedData.type === 'error' && typeof preparedData.message !== 'undefined') {
    return preparedData
  }
  // Get status endpoint
  let requestType = '/activate'
  if (payload.status === 1) {
    requestType = '/deactivate'
  } else if (payload.status === 2) {
    requestType = '/delete'
  }

  return apiClient.post(END_POINT + requestType, { Creative: preparedData }).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

export async function downloadCreativeFile(id, name) {
  apiClient.get(END_POINT + '/download?Id=' + id, { responseType: 'blob' }).then(response => {
    if (response) {
      const blob = new Blob([response.data], { type: 'text/plain' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = name
      link.click()
      URL.revokeObjectURL(link.href)
    }
    return false
  }).catch(err => console.log(err))
}
